<template>
  <v-container id="create">
    <p>{{ activeClassTab }}</p>
    <a
      ref="btnPdf"
      :href="hrefDados"
      style="visibility: hidden"
      download="preorcamento.pdf"
    />
    <v-card>
      <v-tabs
        v-model="tab"
        fixed-tabs
      >
        <v-tab
          @class="activeClassTab = 0 ? 'v-tab v-tab--active' : 'v-tab'"
        >
          <v-icon>mdi-clipboard-text</v-icon>
          <v-spacer />
          Dados
        </v-tab>
        <v-tab
          @class="activeClassTab = 1 ? 'v-tab v-tab--active' : 'v-tab'"
        >
          <v-icon>mdi-truck</v-icon>
          <v-spacer />
          Local de Entrega
        </v-tab>
        <v-tab>
          <v-icon>mdi-database-search</v-icon>
          <v-spacer />
          Lista de Produtos
        </v-tab>
        <v-tab>
          <v-icon>mdi-cart-plus</v-icon>
          <v-btn
            v-if="listaProduto.length > 0"
            color="amber darken-4"
            fab
            x-small
            dark
            class="animate__animated animate__heartBeat"
            text
          >
            <strong class="animate__animated animate__bounce contador">{{ listaProduto.length }}</strong>
          </v-btn>
          <v-spacer />
          Produtos Selecionados
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="pl-4 pr-4"
      >
        <v-tab-item
          transition="slide-x-transition"
          reverse-transition="slide-x-transition"
        >
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="5"
              >
                <compLoja
                  v-if="habLoja"
                  :id="idLoja"
                  :dense="true"
                  :readonly="readOnlyLoja"
                  @onSelectLoja="onSelectLoja"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <div class="d-flex">
                  <v-switch
                    v-model="preOrcamento"
                    inset
                    label="Pré-Orçamento"
                    class="mt-1"
                    :readonly="readOnlyPreOrcamento"
                  />
                  <v-switch
                    v-model="pedido.sujAlteracao"
                    inset
                    label="Sujeito Alteração"
                    class="mt-1 ml-3"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="nomeCliente"
                  label="CPF ou CNPJ*"
                  placeholder="CPF ou CNPJ"
                  outlined
                  dense
                  readonly
                  hide-details
                >
                  <template v-slot:append>
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          medium
                          color="primary"
                          class="mt-0 pt-0"
                          @click="dialogCliente = true"
                        >
                          <v-icon v-on="on">
                            mdi-account-search-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      Pesquisar
                    </v-tooltip>
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          medium
                          color="primary"
                          class="mt-0 pt-0"
                          @click="dialogCadastroCliente = true"
                        >
                          <v-icon v-on="on">
                            mdi-account-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      Novo Cliente
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="4"
                md="2"
              >
                <v-select
                  v-model="tpOrcamento"
                  :items="tipoVenda"
                  label="Tipo:"
                  dense
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="8"
                md="2"
              >
                <compSetorVenda
                  v-if="habSetorVenda"
                  :dense="true"
                  :cdloja="pedido.cdloja"
                  :readonly="readOnlySetorVenda"
                  @onSelectSetorVenda="onSelectSetorVenda"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="pedido.cdEntrega"
                  :items="selectentrega"
                  label="Entrega"
                  item-text="descricao"
                  item-value="cdEntrega"
                  dense
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="8"
                md="6"
              >
                <compPrazoPagto
                  :dense="true"
                  @onSelectPrazo="onSelectPrazo"
                />
              </v-col>
              <v-col
                cols="4"
                md="2"
              >
                <compPrefixoPagto
                  v-if="habPrefPagto"
                  :dense="true"
                  :readonly="readOnlyPrefPagto"
                  :data="dataPagto"
                  :carregardados="carregadadossetorvenda"
                  @onSelectPrefDup="onSelectPrefDup"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                md="3"
              >
                <v-select
                  v-model="codigopreco"
                  :items="selectpreco"
                  label="Preço"
                  item-text="descricao"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  :disabled="desativarPreco"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model="pedido.txMsgOrcamento"
                  outlined
                  label="Observações"
                  :error-messages="txMsgOrcamentoError"
                  @input="$v.pedido.txMsgOrcamento.$touch()"
                  @blur="$v.pedido.txMsgOrcamento.$touch()"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item
          transition="slide-x-transition"
          reverse-transition="slide-x-transition"
          @active-class="activeClassTab == 1 ? 'active' : ''"
        >
          <v-card
            flat
            class="pt-0 mt-0 pb-2"
          >
            <compLocalEntrega
              v-if="dialogLocalEntrega"
              :cpfcnpj="cliente.cdCpfCgc"
              @onAddLocalEntrega="onAddLocalEntrega"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item
          transition="slide-x-transition"
          reverse-transition="slide-x-transition"
          @active-class="activeClassTab == 2 ? 'active' : ''"
        >
          <v-card
            flat
            class="pt-0 mt-0 pb-2"
          >
            <v-card
              flat
              elevation="3"
              class="pb-1 animate__animated animate__fadeInDown"
            >
              <v-row
                class="ml-1 pr-1 mb-0"
                dense
              >
                <v-radio-group
                  v-model="selectPesquisa"
                  row
                  dense
                >
                  <v-radio
                    label="Código"
                    value="C"
                  />
                  <v-radio
                    label="Descrição"
                    value="D"
                  />
                  <v-radio
                    label="Contendo"
                    value="E"
                  />
                </v-radio-group>
              </v-row>
              <v-row
                class="pl-1 pt-0 pr-1 mt-0"
                dense
              >
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    ref="txtcodigo"
                    v-model="paramconsulta"
                    outlined
                    dense
                    hide-details
                    append-icon="mdi-magnify"
                    @click:append="onPesquisaProduto"
                  />
                </v-col>
              </v-row>
            </v-card>
            <v-card
              v-if="consultaproduto"
              flat
              class="pt-0 mt-0 pb-1"
              elevation="3"
            >
              <complistaProduto
                v-if="consultaproduto"
                :tipoconsulta="selectPesquisa"
                :paramconsulta="paramconsulta"
                :tipoorcamento="tpOrcamento"
                :codigopreco="codigopreco"
                :itemheight="this.$isMobile() ? 130 : 100"
                :prazo="prazo"
                :loja="idLoja"
                :preorcamento="preOrcamento"
                @onAddCardProduto="onAddCardProduto"
              />
            </v-card>
          </v-card>
        </v-tab-item>
        <v-tab-item
          transition="slide-x-transition"
          reverse-transition="slide-x-transition"
          class="pb-1"
        >
          <v-card class="animate__animated animate__fadeInDown">
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-if="carregarDesconto"
                    ref="txtQtd"
                    v-model="valorajuste"
                    v-currency="currencyAjuste"
                    label="Ajuste"
                    dense
                    outlined
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-if="carregarDesconto"
                    ref="txtDesconto"
                    v-model="valorDesconto"
                    v-currency="currencyDesconto"
                    label="Vl. Desconto"
                    dense
                    outlined
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="text-end"
                >
                  <!-- <div class="text-end"> -->
                  <v-row
                    dense
                  >
                    <v-col>
                      <span class="fontTotal">Total Geral</span>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                  >
                    <v-col>
                      <span class="fontTotal">R${{ formatPrice(totalGeral) }}</span>
                    </v-col>
                  </v-row>
                  <!-- </div> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-for="(item, index) in listaProduto"
            :key="index"
            color="grey lighten-4"
            class="mt-2 mb-3 pb-0"
          >
            <v-row dense>
              <v-col
                cols="2"
                class="d-flex justify-center align-center"
              >
                <v-btn
                  class="ma-2"
                  outlined
                  fab
                  color="red"
                  dense
                  x-small
                  @click="onExcluirItem(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="10">
                <v-card-text class="d-flex justify-start pa-1">
                  {{ item.descricao }}
                </v-card-text>
                <v-card-text class="d-flex justify-start pa-1">
                  <strong class="primary--text pr-1 fontDesc">Unid:</strong>
                  <strong class="fontDesc">{{ item.cdUnidade }}</strong>
                  <strong class="primary--text pr-1 pl-1 fontDesc">Qtd:</strong>
                  <strong class="fontDesc">{{ formatPriceQtd(item.qtVendaPadrao) }}</strong>
                  <strong class="primary--text pr-1 pl-1 fontDesc">Vl.Unit:</strong>
                  <strong class="fontDesc">{{ formatPrice(item.vlUnitario) }}</strong>
                  <strong class="primary--text pr-1 pl-1 fontDesc">Vl.Tot.:</strong>
                  <strong class="fontDesc">{{ formatPrice(item.total) }}</strong>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      v-model="dialogCliente"
      persistent
      scrollable
      max-width="850"
    >
      <v-card>
        <modalCliente
          v-if="dialogCliente"
          @onSelectCliente="onSelectCliente"
        />
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialogCliente = false"
          >
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCadastroCliente"
      scrollable
      max-width="850"
    >
      <modalCadastroCliente
        v-if="dialogCadastroCliente"
        :editar="editarCliente"
        @onSucessoCadastro="onSucessoCadastro"
        @onSairCliente="onSairCliente"
      />
    </v-dialog>
    <v-speed-dial
      v-model="fab"
      elevation="2"
      bottom
      right
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="green darken-2"
          dark
          fab
          small
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-menu
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="green"
        @click="onSalvar"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
        @click="onLimparCampos"
      >
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>
<script>
  import { maxLength } from 'vuelidate/lib/validators'
  // COMPONENTES
  import modalCliente from './../../componentes/modal-listacliente'
  import compPrazoPagto from './../../componentes/componente-prazopagto'
  import compPrefixoPagto from './../../componentes/componente-prefixopagto'
  import complistaProduto from './../../componentes/lista-produto'
  import compLoja from './../../componentes/componente-loja'
  import compSetorVenda from './../../componentes/componente-setorvenda'
  import compLocalEntrega from './../../componentes/componente-localentrega'
  import modalCadastroCliente from './../../componentes/cliente/cadastro-cliente'
  // REPOSITORY
  import orcamentoRep from './../../../components/services/orcamentoRepository'
  import transacaoRep from './../../../components/services/transacaoRepository'

  export default {
    components: {
      modalCliente,
      complistaProduto,
      compPrazoPagto,
      compPrefixoPagto,
      compSetorVenda,
      compLoja,
      compLocalEntrega,
      modalCadastroCliente,
    },
    validations: {
      pedido: {
        txMsgOrcamento: {
          maxLength: maxLength(200),
        },
      },
    },
    data () {
      return {
        tab: 0,
        itemUnid: ['UND'],
        tipoVenda: ['A', 'X'],
        activeClassTab: 0,
        selectpreco: [
          {
            descricao: 'Preço 1',
            value: 0,
          },
          {
            descricao: 'Preço 2',
            value: 1,
          },
          // {
          //   descricao: 'Preço 3',
          //   value: 3,
          // },
          {
            descricao: 'A Revisar',
            value: 2,
          },
        ],
        // VARIAVES
        nomeCliente: '',
        carregadadossetorvenda: false,
        selectPesquisa: 'D',
        consultaproduto: false,
        paramconsulta: '',
        codigopreco: 0,
        // DIALOGS
        dialogCliente: false,
        dialogLocalEntrega: false,
        dialogCadastroCliente: false,
        // Habilita Campos
        habPrefPagto: true,
        habSetorVenda: true,
        habLoja: true,
        // readOnly
        readOnlyPrefPagto: true,
        readOnlySetorVenda: true,
        readOnlyLoja: false,
        readOnlyPreOrcamento: false,
        // ID`s
        dataPagto: {},
        idLoja: 0,
        pedido: {
          cdtipo: 'X',
          txMsgOrcamento: '',
          preOrcamento: false,
          sujAlteracao: false,
        },
        listaProduto: [],
        localEntrega: {},
        totalGeral: 0.00,
        cliente: {},
        // transition
        transition: 'slide-y-reverse-transition',
        fab: false,
        // ------
        prazo: 0,
        prefDup: '',
        setorVenda: 0,
        editarCliente: false,
        loja: {},
        currencyAjuste: {
          currency: null,
          valueAsInteger: true,
          distractionFree: false,
          precision: 0,
          autoDecimalMode: false,
          allowNegative: false,
        },
        currencyDesconto: {
          currency: null,
          valueAsInteger: true,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
        valorajuste: '0',
        valorDesconto: '0,00',
        carregarDesconto: true,
        selectentrega: [
          {
            cdEntrega: 1,
            descricao: 'Empresa',
          }, {
            cdEntrega: 0,
            descricao: 'Cliente',
          }, {
            cdEntrega: 2,
            descricao: 'Apanha',
          }],
        desativarPreco: false,
        tpOrcamento: 'X',
        usuarioteste: {},
        preOrcamento: false,
        hrefDados: '',
        acessoLiberado: false,
      }
    },
    computed: {
      txMsgOrcamentoError () {
        const errors = []
        if (!this.$v.pedido.txMsgOrcamento.$dirty) return errors
        !this.$v.pedido.txMsgOrcamento.maxLength && errors.push('Tamanho máximo 200')
        return errors
      },
    },
    watch: {
      tab: function (val) {
        if (val === 0) {
          if (this.listaProduto.length !== 0) {
            this.readOnlyPreOrcamento = true
          } else {
            this.readOnlyPreOrcamento = false
          }
        }
        if (val === 2) {
          if (this.idLoja === 0) {
            this.tab = 0
            this.$swal({
              icon: 'warning',
              text: 'Selecione a Loja',
            })
          }
          if (this.codigopreco === -1) {
            this.tab = 0
            this.activeClassTab = 1
            this.$swal({
              icon: 'warning',
              text: 'Selecione o preço',
            })
            setTimeout(() => {
              this.activeClassTab = 0
            }, 500)
          }
        }
      },
      tpOrcamento: function () {
        if (typeof (this.dataPagto.cdPrazo) !== 'undefined') {
          this.habPrefPagto = false
          setTimeout(() => {
            this.habPrefPagto = true
            this.dataPagto.tipoOrc = this.tpOrcamento
            this.carregadadossetorvenda = true
          }, 1000)
        }
      },
      selectPesquisa: function () {
        this.paramconsulta = ''
        this.consultaproduto = false
      },
      valorajuste: function (value) {
        this.onCalcularAjuste(value)
      },
      valorDesconto: function (value) {
        this.onCalcularDesconto(value)
      },
      preOrcamento: function (value) {
        if (this.listaProduto.length !== 0) {
          this.readOnlyPreOrcamento = value
        }
      },
    },
    async created () {
      this.usuarioteste = this.$store.getters.usuario
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
        if (this.acessoLiberado === false) {
          this.$router.replace('/v1')
        }
      }
      if (this.$store.getters.empresa.nome.toUpperCase() === 'JUAÇO') {
        this.codigopreco = -1
        this.selectpreco.splice(0, 1)
      }
    },
    methods: {
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FP001ORC',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else {
            this.$store.commit('setSplashScreen', false)
            this.acessoLiberado = true
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onCalcularDesconto (value) {
        if (parseFloat(value.replace('.', '').replace(',', '.')) <= this.loja.vldesconto) {
          this.calcularTotalGeral()
          if (this.listaProduto.length > 0) {
            this.totalGeral = this.totalGeral - parseFloat(value.replace('.', '').replace(',', '.'))
          }
        } else {
          this.calcularTotalGeral()
          setTimeout(() => {
            this.valorDesconto = '0,00'
          }, 1000)
          this.$swal({
            icon: 'warning',
            text: 'Valor superior à R$' + this.formatPrice(parseFloat(this.loja.vldesconto)) + '!',
          })
        }
      },
      async onCalcularAjuste (value) {
        if (this.listaProduto.length > 0) {
          for (let index = 0; index < this.listaProduto.length; index++) {
            this.listaProduto[index].FlReservado = 1
            if (parseInt(value) > 100 && parseInt(this.listaProduto[index].maxajuste) > 100) {
              if (this.codigopreco === 2) {
                this.listaProduto[index].vlUnitario = parseFloat(this.formatPrice((this.listaProduto[index].vlTabela / (parseInt(value) * -1) * 100) * -1).replace('.', '').replace(',', '.'))
                this.listaProduto[index].vlUnitarioPadrao = this.listaProduto[index].vlUnitario
                this.listaProduto[index].total = this.listaProduto[index].qtVenda * this.listaProduto[index].vlUnitario
                this.listaProduto[index].vlAjuste = parseInt(value)
              } else {
                if (parseInt(value) <= parseInt(this.listaProduto[index].maxajuste)) {
                  this.listaProduto[index].vlUnitario = parseFloat(this.formatPrice((this.listaProduto[index].vlTabela / (parseInt(value) * -1) * 100) * -1).replace('.', '').replace(',', '.'))
                  this.listaProduto[index].vlUnitarioPadrao = this.listaProduto[index].vlUnitario
                  this.listaProduto[index].total = this.listaProduto[index].qtVenda * this.listaProduto[index].vlUnitario
                  this.listaProduto[index].vlAjuste = parseInt(value)
                } else {
                  this.listaProduto[index].vlUnitario = parseFloat(this.formatPrice((this.listaProduto[index].vlTabela / (parseInt(this.listaProduto[index].maxajuste) * -1) * 100) * -1).replace('.', '').replace(',', '.'))
                  this.listaProduto[index].vlUnitarioPadrao = this.listaProduto[index].vlUnitario
                  this.listaProduto[index].total = this.listaProduto[index].qtVenda * this.listaProduto[index].vlUnitario
                  this.listaProduto[index].vlAjuste = parseInt(this.listaProduto[index].maxajuste)
                }
              }
            } else {
              if (parseInt(value) !== 100) { // QUALQUER VALOR DIFERENTE PEGA O VALOR DE TABELA
                this.listaProduto[index].vlUnitario = this.listaProduto[index].vlTabela
                this.listaProduto[index].vlUnitarioPadrao = this.listaProduto[index].vlTabela
                this.listaProduto[index].total = this.listaProduto[index].qtVenda * this.listaProduto[index].vlTabela
                this.listaProduto[index].vlAjuste = 0
              } else { // SE FOR MENOR QUE 100 PEGA O VALOR UNITARIO DA INCLUSÃO DO PRODUTO
                this.listaProduto[index].vlUnitarioPadrao = this.listaProduto[index].vlUnitario
                this.listaProduto[index].total = this.listaProduto[index].qtVenda * this.listaProduto[index].vlUnitario
                this.listaProduto[index].vlAjuste = 0
              }
            }
          }
          await this.calcularTotalGeral()
          await this.onCalcularDesconto(this.valorDesconto)
        }
      },
      onSelectLoja (loja) {
        this.habLoja = false
        this.readOnlyLoja = true
        this.habSetorVenda = false
        this.pedido.cdloja = loja.cdLoja
        this.loja = loja
        setTimeout(() => {
          this.readOnlySetorVenda = false
          this.habSetorVenda = true
          this.habLoja = true
          this.idLoja = loja.cdLoja
        }, 500)
      },
      onSelectCliente (cliente) {
        this.dialogLocalEntrega = false
        this.dialogCliente = false
        this.flNaoClienteSelecionado = false
        this.nomeCliente = cliente.cdCpfCgcFormatado + '-' + cliente.nmCliente
        this.pedido.nmcliente = cliente.nmCliente
        this.cliente = cliente
        setTimeout(() => {
          this.dialogLocalEntrega = true
        }, 500)
      },
      onSelectPrazo (data) {
        this.habPrefPagto = false
        this.prazo = data.cdPrazo
        setTimeout(() => {
          this.readOnlyPrefPagto = false
          this.habPrefPagto = true
          this.dataPagto = data
          this.dataPagto.tipoOrc = this.tpOrcamento
          this.carregadadossetorvenda = true
        }, 1000)
      },
      onPesquisaProduto () {
        this.consultaproduto = false
        setTimeout(() => {
          this.consultaproduto = true
        }, 200)
      },
      onAddCardProduto (item) {
        var data = this.listaProduto.some((itemArray) => {
          return itemArray.item.trim() === item.item.trim()
        })
        if (data === true) {
          this.$swal({
            icon: 'warning',
            text: 'Produto já Adicionado!',
          })
        } else {
          this.desativarPreco = true
          this.listaProduto.push(item)
          if (parseInt(this.valorajuste) > 100) this.onCalcularAjuste(this.valorajuste)
          this.calcularTotalGeral()
        }
      },
      onExcluirItem (index) {
        this.listaProduto.splice(index, 1)
        if (this.listaProduto.length === 0) this.desativarPreco = false
        this.calcularTotalGeral()
        this.onCalcularDesconto(this.valorDesconto)
      },
      onAddLocalEntrega (localEntrega) {
        this.localEntrega = localEntrega
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      formatPriceQtd (value) {
        var val = (value / 1).toFixed(4).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '.')
      },
      async calcularTotalGeral () {
        this.totalGeral = 0.00
        for (let index = 0; index < this.listaProduto.length; index++) {
          this.totalGeral += this.listaProduto[index].total
        }
      },
      async onSalvar () {
        try {
          if (this.onValidarSalvar()) {
            this.onCarregarDadosSalvar()
            this.$store.commit('setSplashScreen', true)
            this.pedido.itens[0].vldesconto = parseFloat(this.valorDesconto.replace('.', '').replace(',', '.'))
            await orcamentoRep.Salvar(this.pedido).then(response => {
              this.$store.commit('setSplashScreen', false)
              if (response.data.status === 'sucesso') {
                if (this.preOrcamento === true) {
                  var urlbase = this.$store.getters.empresa.url.includes('locahost') ? this.$store.getters.empresa.url.replace('/Api/', '').replace('/api/', '') + 'temp/' + response.data.dados : this.$store.getters.empresa.url.replace('/sfcloud/Api/', '').replace('/sfcloud/api/', '') + '/temp/' + response.data.dados
                  window.location.href = urlbase
                } else {
                  this.$swal({
                    icon: 'success',
                    text: 'Pedido Realizado com Sucesso!',
                  })
                }
                this.onLimparCampos()
                this.carregarDesconto = false
                setTimeout(() => {
                  this.carregarDesconto = true
                  this.preOrcamento = false
                }, 1000)
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.dados,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              } else {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'error',
                text: error,
              })
            })
          }
        } catch (error) {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
      },
      onCarregarDadosSalvar () {
        for (let index = 0; index < this.listaProduto.length; index++) {
          this.listaProduto[index].flReservado = 1
        }
        this.pedido = {
          cdLoja: this.idLoja,
          cdVendedor: this.$store.getters.usuario.cdvendedor,
          cdCpfCgc: this.cliente.cdCpfCgc.replace(/[^\d]+/g, ''),
          nrSeq: this.localEntrega != null && this.localEntrega.nrSeq != null
            ? this.localEntrega.nrSeq
            : 0,
          cdPrazo: this.prazo,
          cdTipo: this.tpOrcamento,
          vlDesconto: parseFloat(this.valorDesconto.replace('.', '').replace(',', '.')),
          vlTotal: this.totalGeral,
          txMsgOrcamento: this.pedido.txMsgOrcamento,
          cdFrete: 'C',
          stOrcamento: this.codigopreco === 2 ? 'A' : 'N',
          cdEntrega: this.pedido.cdEntrega, // CLIENTE OU EMPRESA
          flEntregaEsp: false,
          nmLocalEntrega:
            this.localEntrega != null && this.localEntrega.nmLocalEntrega != null
              ? this.localEntrega.nmLocalEntrega
              : '',
          nmEnder: this.localEntrega != null && this.localEntrega.nmEnder != null
            ? this.localEntrega.nmEnder
            : '',
          nrEnder: this.localEntrega != null && this.localEntrega.nrEnder != null
            ? this.localEntrega.nrEnder
            : this.cliente.nrEnder,
          nmComplEnder: this.localEntrega != null && this.localEntrega.nmComplEnder != null
            ? this.localEntrega.nmComplEnder
            : this.cliente.nmComplEnder,
          nmBairro: this.localEntrega != null && this.localEntrega.nmBairro != null
            ? this.localEntrega.nmBairro
            : this.cliente.nmBairro,
          cdCidade: this.localEntrega != null && this.localEntrega.cdCidade != null
            ? this.localEntrega.cdCidade
            : this.cliente.cdCidade,
          cdUf: this.localEntrega != null && this.localEntrega.cdUf != null
            ? this.localEntrega.cdUf.toUpperCase()
            : this.cliente.cdUf.toUpperCase(),
          cdBairro: this.localEntrega != null && this.localEntrega.cdBairro != null
            ? this.localEntrega.cdBairro
            : this.cliente.cdBairro,
          cdCep: 0,
          nrDdd: this.localEntrega != null && this.localEntrega.nrDdd != null
            ? this.localEntrega.nrDdd
            : this.cliente.nrDdd == null ? 0 : this.cliente.nrDdd,
          nrFone: this.localEntrega != null && this.localEntrega.nrFone != null
            ? this.localEntrega.nrFone
            : this.cliente.nrFone1 == null ? 0 : this.cliente.nrFone1,
          txPontoRefer: this.localEntrega != null && this.localEntrega.txPontoRefer != null
            ? this.localEntrega.txPontoRefer
            : this.cliente.nmComplEnder,
          cdTramitacao: 1,
          cdUsuario: this.$store.getters.usuario.cdusuario,
          txMsgCaixa: '',
          vlPesoTotal: this.onCalcularTotalPeso('pesoTotalVenda'),
          nmCliente: this.cliente.nmCliente.trim(),
          nrDiasPagto: 0,
          txMsgPrazo: '',
          nrSeqCobranca: 0,
          cdLojaOrigem: 1,
          vlProdutos: this.totalGeral,
          vlTotalIpi: 0,
          flUrgente: 0,
          flAprovado: 0,
          vlAjuste: this.valorajuste === '' ? 0 : parseInt(this.valorajuste.replace('.', '').replace(',', '.')),
          flFat: 0,
          cdLojaCobranca: this.idLoja,
          vlComissao: 0,
          flNpAss: 0,
          cdPrefDup: this.prefDup,
          flAlteracao: this.pedido.sujAlteracao ? 1 : 0,
          nrDiasEntrega: 0,
          vlTotalRegra: 1,
          cdRegra: 0,
          cdTipoPreco: this.codigopreco === 2 ? 6 : this.codigopreco === 0 ? 1 : 2,
          cdBanco: 0,
          flEstoqueLocal: true,
          cdLojaImpressao: this.idLoja,
          cdSetorVenda: this.setorVenda,
          flCorteDobra: false,
          nrCGF: '',
          preOrcamento: this.preOrcamento,
          itens: this.listaProduto,
        }
      },
      onSelectPrefDup (prefDup) {
        this.prefDup = prefDup
      },
      onSelectSetorVenda (setorVenda) {
        this.setorVenda = setorVenda
      },
      onSucessoCadastro () {
        this.dialogCadastroCliente = false
      },
      onSairCliente () {
        this.dialogCadastroCliente = false
      },
      onValidarSalvar () {
        this.$v.$touch()
        if (this.idLoja === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Selecione a Loja',
          })
          return false
        } else if (typeof (this.cliente.cdCpfCgc) === 'undefined') {
          this.$swal({
            icon: 'warning',
            text: 'Selecione o Cliente',
          })
          return false
        } else if (typeof (this.tpOrcamento) === 'undefined') {
          this.$swal({
            icon: 'warning',
            text: 'Selecione o Tipo de Orçamento.',
          })
          return false
        } else if (this.setorVenda === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Selecione o Setor de Venda.',
          })
          return false
        } else if (this.prazo === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Selecione o Prazo.',
          })
          return false
        } else if (this.prefDup === '') {
          this.$swal({
            icon: 'warning',
            text: 'Selecione o Prefixo Duplicata.',
          })
          return false
        } else if (typeof (this.codigopreco) === 'undefined') {
          this.$swal({
            icon: 'warning',
            text: 'Selecione o Preço.',
          })
          return false
        } else if (this.listaProduto.length === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Selecione um produto.',
          })
          return false
        } else if (this.$v.$invalid) { return false }
        return true
      },
      onCalcularTotalPeso (item) {
        return this.listaProduto.reduce((a, b) => a + (b[item] || 0), 0)
      },
      onLimparCampos () {
        this.idLoja = 0
        this.nomeCliente = ''
        this.pedido.cdEntrega = 0
        this.prefDup = ''
        this.pedido.txMsgOrcamento = ''
        this.tpOrcamento = 'X'
        this.listaProduto = []
        this.localEntrega = {}
        this.totalGeral = 0.00
        this.valorajuste = '0'
        this.valorDesconto = '0,00'
        this.tab = 0
        this.paramconsulta = ''
        // this.habSetorVenda = false
        this.readOnlyLoja = false
        this.readOnlyPreOrcamento = false
        this.habLoja = false
        this.habPrefPagto = false
        this.desativarPreco = false
        this.consultaproduto = false
        setTimeout(() => {
          // this.habSetorVenda = true
          this.habPrefPagto = true
          this.habLoja = true
          this.consultaproduto = true
        }, 1000)
      },
    },
  }
</script>
<style>
  .fontDesc {
    font-size: 12px;
  }
  .fontTotal {
    font-size: 20px;
    font-weight: 500;
    color: #0D47A1;
  }
  .contador {
    font-size: 16px;
    position: relative; top: -10px; left: -15px; z-index: -1;
  }
  #create .v-speed-dial {
    position: absolute;
  }

  #create .v-btn--floating {
    position: relative;
  }
</style>
